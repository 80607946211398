import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const attr = "data-open";
const val = "";

let menuOpenButton;
let menuCloseButton;
let header;
let navigation;

function menuOpen() {
  header.setAttribute(attr, val);
  disableBodyScroll(navigation);
}

function menuClose() {
  header.removeAttribute(attr);
  enableBodyScroll(navigation);
}

function toggleNavigation() {
  if (header.hasAttribute(attr) && header.getAttribute(attr) === val) {
    menuClose();
  } else {
    menuOpen();
  }
}

function init() {
  menuOpenButton = document.getElementById("menu-open");
  menuCloseButton = document.getElementById("menu-close");
  header = document.getElementById("header");
  navigation = document.getElementById("navigation");

  menuOpenButton.addEventListener("click", toggleNavigation);
  menuCloseButton.addEventListener("click", toggleNavigation);

  [...document.querySelectorAll(".route")].forEach((route) => {
    route.addEventListener("click", menuClose);
  });
}

document.addEventListener("DOMContentLoaded", init);
